import { cn } from '@pbx/shared/utilities/combine-class-names';

import { TBlockContainer } from './block-container.types';
import { variants } from './block-container.variants';

export function BlockContainer({
  paddingVariant,
  removeTopPadding = false,
  removeBottomPadding = false,
  padding = true,
  children,
  className = '',
  id,
}: TBlockContainer) {
  return (
    <section
      id={id}
      data-testid="block-container-section"
      className={cn(
        variants({
          removeTopPadding,
          removeBottomPadding,
          padding,
          paddingVariant,
        }),
        className
      )}
    >
      {children}
    </section>
  );
}
