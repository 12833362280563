import React from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@pbx/shared/utilities/combine-class-names';

import { TTypographyProps } from './text.types';

export const textVariants = cva('', {
  variants: {
    variant: {
      hero: 'font-semibold font-display',
      display: 'font-semibold font-display',
      heading: 'font-medium font-display',
      paragraph: 'font-normal font-body',
      ui: 'font-ui',
      label: 'font-ui',
    },
    size: {
      small: '',
      smaller: '',
      smallest: '',
      regular: '',
      medium: '',
      large: '',
      'large-medium': '',
      'small-tight': '',
      'small-400': '',
      'small-500': '',
      'medium-tight': '',
      'medium-400': '',
      'medium-500': '',
      'large-400': '',
      'large-500': '',
      'default-400': '',
      'default-500': '',
    },
  },
  compoundVariants: [
    {
      variant: 'hero',
      size: 'large',
      class: 'sm:text-plus-7 md:text-plus-8 lg:text-plus-9',
    },
    {
      variant: 'hero',
      size: 'medium',
      class: 'sm:text-plus-6 md:text-plus-7 lg:text-plus-8',
    },
    {
      variant: 'display',
      size: 'large',
      class: 'sm:text-plus-5 md:text-plus-6 lg:text-plus-7',
    },
    {
      variant: 'display',
      size: 'medium',
      class: 'sm:text-plus-4 md:text-plus-5 lg:text-plus-6',
    },
    {
      variant: 'display',
      size: 'small',
      class: 'sm:text-plus-3 md:text-plus-4 lg:text-plus-5',
    },
    {
      variant: 'heading',
      size: 'large',
      class: 'sm:text-plus-4 md:text-plus-5 lg:text-plus-6',
    },
    {
      variant: 'heading',
      size: 'medium',
      class: 'sm:text-plus-3 md:text-plus-3 lg:text-plus-5',
    },
    {
      variant: 'heading',
      size: 'small',
      class: 'sm:text-plus-2 md:text-plus-2 lg:text-plus-3',
    },
    {
      variant: 'heading',
      size: 'smaller',
      class: 'sm:text-plus-1 md:text-plus-1 lg:text-plus-1',
    },
    {
      variant: 'heading',
      size: 'smallest',
      class: 'sm:text-base md:text-base lg:text-base',
    },
    {
      variant: 'paragraph',
      size: 'large',
      class: 'text-xl leading-8',
    },
    {
      variant: 'paragraph',
      size: 'medium',
      class: 'text-base leading-7',
    },
    {
      variant: 'paragraph',
      size: 'medium-tight',
      class: 'text-base leading-6',
    },
    {
      variant: 'paragraph',
      size: 'small',
      class: 'text-sm leading-6',
    },
    {
      variant: 'paragraph',
      size: 'small-tight',
      class: 'text-sm leading-5',
    },
    {
      variant: 'ui',
      size: 'medium-400',
      class: 'text-base leading-6 font-normal',
    },
    {
      variant: 'ui',
      size: 'medium-500',
      class: 'text-base leading-6 font-medium',
    },
    {
      variant: 'ui',
      size: 'large-400',
      class: 'text-2xl leading-8 font-normal',
    },
    {
      variant: 'ui',
      size: 'large-500',
      class: 'text-2xl leading-8 font-medium',
    },
    {
      variant: 'label',
      size: 'default-400',
      class: 'text-base font-normal',
    },
    {
      variant: 'label',
      size: 'default-500',
      class: 'text-base font-medium',
    },
    {
      variant: 'label',
      size: 'large-400',
      class: 'sm:text-plus-2 md:text-plus-2 lg:text-plus-2 font-normal',
    },
    {
      variant: 'label',
      size: 'large-500',
      class: 'sm:text-plus-2 md:text-plus-2 lg:text-plus-2 font-medium',
    },
    {
      variant: 'label',
      size: 'small-400',
      class: 'text-minus-1 font-normal leading-5',
    },
    {
      variant: 'label',
      size: 'small-500',
      class: 'text-minus-1 font-medium leading-5',
    },
  ],
  defaultVariants: {
    variant: 'paragraph',
    size: 'medium',
  },
});

export const Text = React.forwardRef<HTMLHeadingElement, TTypographyProps>(
  ({ className, variant, as, size, ...props }, ref) => {
    const Comp = as || 'span';

    return (
      <Comp
        className={cn(textVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  }
);
Text.displayName = 'Text';
