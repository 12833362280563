import { ReactNode } from 'react';

import { BlockContainer } from '@pbx/shared/contentful/ui/block-container';

import {
  TContentBlock,
  TComponentMap,
} from '../transform-page-data/transform-page-data.types';

export const renderContentfulBlocks = (
  blocks: TContentBlock[],
  components: TComponentMap
): ReactNode => {
  const blockTypeCount: { [key: string]: number } = {}; // Tack occurrences of each block type

  return (blocks || []).map((block, index) => {
    const { type, fields: properties } = block;
    const [Component, options = {}] = components[type] || [];
    const { props = {}, sectionProps = {} } = options;
    const { padding, paddingVariant } = sectionProps;

    /*
     * Create a unique id for each block based on its type and the number
     * of occurrences. This is useful for linking to specific blocks.
     */
    if (blockTypeCount[type]) {
      blockTypeCount[type]++;
    } else {
      blockTypeCount[type] = 1;
    }

    const uniqueId = `${type}${blockTypeCount[type]}`;

    return Component ? (
      <BlockContainer
        id={uniqueId}
        key={index}
        padding={padding}
        paddingVariant={paddingVariant}
        removeTopPadding={properties?.removeTopPadding}
        removeBottomPadding={properties?.removeBottomPadding}
      >
        <Component {...properties} {...props} />
      </BlockContainer>
    ) : null;
  });
};
