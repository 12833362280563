import { TEntry } from '@pbx/shared/contentful/types';

export function getContentType(entry: TEntry) {
  if (entry?.sys?.contentType?.sys?.id) {
    return entry.sys.contentType.sys.id;
  }

  if (entry?.fields?.file) {
    return 'file';
  }

  return '';
}
