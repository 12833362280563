import { TEntry } from '@pbx/shared/contentful/types';

import { transformContentfulData } from '../transform-contentful-data/transform-contentful-data';
import { TSeoData, TSeoFields } from './transform-seo-data.types';

export const transformSeoData = (pageData: TEntry): TSeoData => {
  const seo = pageData?.fields?.seo as TSeoFields;
  const { title, excerpt, thumbnail } = transformContentfulData(pageData);

  return {
    ...seo?.fields,
    seoTitle: (seo?.fields?.seoTitle || title) as string,
    title,
    excerpt,
    thumbnail,
  } as TSeoData;
};
