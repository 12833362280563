import { TEntry, TSpaceConfigEntry } from '@pbx/shared/contentful/types';

import { getContentType } from '../get-content-type/get-content-type';
import { transformContentfulData } from '../transform-contentful-data/transform-contentful-data';

export const transformComponentsData = <T extends Record<string, any>>(
  config: TSpaceConfigEntry
): T => {
  const {
    fields: { components = [] },
  } = config;

  return components.reduce((result, value) => {
    const configEntry = value as TEntry;
    const type = getContentType(configEntry);

    const {
      sys: { id: entryId },
    } = configEntry;

    const data = transformContentfulData(configEntry) || {};

    return {
      ...result,
      [type]: {
        ...result[type],
        [entryId]: data,
      },
    };
  }, {} as T);
};
