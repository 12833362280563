import { TEntry, TEntryFields, TFieldName } from '@pbx/shared/contentful/types';

import { getContentType } from '../get-content-type/get-content-type';
import {
  flattenFile,
  flattenLink,
  flattenNavigation,
  flattenButton,
  flattenTag,
  flattenPage,
  flattenFAQItem,
  flattenFeatureList,
  flattenServiceCard,
  flattenQuickActionCard,
  flattenBlockRichText,
  flattenIcon,
  flattenSequenceItem,
  flattenListItem,
  flattenTabContentItem,
  flattenRelatedContentCard,
} from './flatten-content-models/flatten-content-models';
import {
  FlattenFunction,
  TImg,
  TLink,
  TNavigation,
} from './flatten-content-models/flatten-content-models.types';

const flatteners: Record<TFieldName, FlattenFunction> = {
  link: flattenLink,
  file: flattenFile,
  navigation: flattenNavigation,
  button: flattenButton,
  tag: flattenTag,
  page: flattenPage,
  faqItem: flattenFAQItem,
  featureList: flattenFeatureList,
  quickActionCard: flattenQuickActionCard,
  serviceCard: flattenServiceCard,
  blockRichText: flattenBlockRichText,
  icon: flattenIcon,
  sequenceItem: flattenSequenceItem,
  tabbedContentItem: flattenTabContentItem,
  listItem: flattenListItem,
  relatedContentCard: flattenRelatedContentCard,
};

export function transformContentfulData(
  data: TEntry
): Record<string, TLink | TImg | TNavigation | string | number | boolean> {
  const fields = data?.fields as TEntryFields;
  const result: Record<string, TLink | TImg | TNavigation> = {};

  if (!fields) return result;

  Object.keys(fields)?.forEach((key) => {
    const value = fields[key as TFieldName];

    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean'
    ) {
      result[key] = value;
    } else if (Array.isArray(value)) {
      result[key] = value.map((item) => {
        const type = getContentType(item) as TFieldName;
        const flattener = flatteners[type];
        return flattener ? flattener(item) : item;
      });
    } else if (typeof value === 'object') {
      const type = getContentType(value as TEntry) as TFieldName;
      const flattener = flatteners[type];

      if (flattener) {
        // TODO: fix type
        result[key] = flattener(value as any);
      } else {
        // Handle other types of objects
        result[key] = value;
      }
    }
  });

  return result;
}
