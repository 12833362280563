import { TPageEntry, TSeoEntry } from '@pbx/apps/marketing-website/types';
import {
  TPageEntry as TPageEntryMortgages,
  TSeoEntry as TSeoEntryMortgages,
} from '@pbx/apps/mortgages-marketing-website/types';
import { TEntry } from '@pbx/shared/contentful/types';

import { transformContentfulData } from '../transform-contentful-data/transform-contentful-data';
import { getContentType } from '../get-content-type/get-content-type';
import { TComponent, EPageType, TPageData } from './transform-page-data.types';
import { TImg } from '../transform-contentful-data/flatten-content-models/flatten-content-models.types';

export const transformPageData = (
  pageData: TPageEntry | TPageEntryMortgages
): TPageData => {
  const { fields: pageFields } = pageData;
  const { schema } =
    (pageFields.seo as TSeoEntry | TSeoEntryMortgages)?.fields || {};

  const pageType = getContentType(pageData) as EPageType;
  const { updatedAt, createdAt } = pageData.sys;
  const { title, body, slug, excerpt } = pageFields;
  const { thumbnail } = transformContentfulData(pageData);

  const contentBlocks = transformContentBlocks(body as TEntry[]);

  return {
    content: contentBlocks,
    pageType: pageType,
    slug: slug,
    title: title,
    thumbnail: thumbnail as TImg,
    createdAt,
    updatedAt,
    excerpt,
    schema,
  };
};

export function transformContentBlocks(blocks: TEntry[]) {
  return blocks.map((block) => {
    const contentBlock = block;
    const type = getContentType(contentBlock) as TComponent;
    const fields = transformContentfulData(contentBlock);

    return {
      type,
      fields,
    };
  });
}
