import { cva } from 'class-variance-authority';

const resetBottomPadding = 'sm:pb-0 md:pb-0 lg:pb-0';
const resetTopPadding = 'sm:pt-0 md:pt-0 lg:pt-0';

export const variants = cva('', {
  variants: {
    paddingVariant: {
      1: '',
    },
    removeTopPadding: {
      true: '',
      false: 'sm:pt-8 md:pt-12 lg:pt-16',
    },
    removeBottomPadding: {
      true: '',
      false: 'sm:pb-8 md:pb-12 lg:pb-16',
    },
    padding: {
      true: '',
      false: `${resetTopPadding} ${resetBottomPadding}`,
    },
  },
  compoundVariants: [
    {
      paddingVariant: 1,
      removeTopPadding: false,
      class: 'sm:pt-4 md:pt-6 lg:pt-8',
    },
    {
      paddingVariant: 1,
      removeBottomPadding: false,
      class: 'sm:pb-8 md:pb-12 lg:pb-16',
    },
    {
      paddingVariant: 1,
      removeTopPadding: true,
      class: resetTopPadding,
    },
    {
      paddingVariant: 1,
      removeBottomPadding: true,
      class: resetBottomPadding,
    },
  ],
});
