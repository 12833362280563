import { TRichTextDocument } from '@pbx/shared/contentful/types';

export const replaceRichTextTags = (
  document: TRichTextDocument,
  replacements: Record<string, string | number>
): TRichTextDocument => {
  return {
    ...document,
    content: document.content.map((paragraph) => ({
      ...paragraph,
      content: paragraph.content.map((textNode) => {
        if (textNode.nodeType === 'text') {
          let newValue = textNode.value;
          for (const [placeholder, replacement] of Object.entries(
            replacements
          )) {
            newValue = newValue.replace(placeholder, String(replacement));
          }
          return { ...textNode, value: newValue };
        }
        return textNode;
      }),
    })),
  };
};
